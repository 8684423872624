import { v4 } from 'uuid';

import { FaqCategories } from '@pages/private/shared/FaqCategories';
import { Faqs } from '@pages/private/shared/FaqCategories/Faqs';
import { POSTransactions } from '@pages/private/shared/Sells/POSExtract';
import { ViewNews } from '@pages/private/shared/News/View';
import { MyInformation } from '@pages/private/shared/Me';
import { CreateTickets } from '@pages/private/shared/Tickets/Create';
import { ListTickets } from '@pages/private/shared/Tickets/List';
import { UpdateTickets } from '@pages/private/shared/Tickets/Update';
import { Dashboard } from '@pages/private/shared/Dashboard';
import { ListClientsFromAnybody } from '@pages/private/sellers/Clients/List';
import { SharedClientDetails } from '@pages/private/sellers/Clients/Details';
import { CreateClients } from '@pages/private/sellers/Clients/New';
import { NewInvestment } from '@pages/private/shared/BbankInvest/Investments/New';
import { ListInvestments } from '@pages/private/shared/BbankInvest/Investments/List';
import { NewTransfer } from '@pages/private/admin/Bwallet/Transfer/New';
import { TransferToAccount } from '@pages/private/admin/Bwallet/TransferToAccount/New';
import { OperationP2P } from '@pages/private/admin/Bwallet/OperationP2P/New';
import { NewPaygamentPix } from '@pages/private/admin/Bwallet/PaymentPix/New';
import { NewCardPayment } from '@pages/private/admin/Bwallet/CardPayment/New';
import { BilletDetails } from '@pages/private/admin/Bwallet/Billets/Details';
import { NewBillet } from '@pages/private/admin/Bwallet/Billets/New';
import { ListBillets } from '@pages/private/admin/Bwallet/Billets/List';
import { ListInvestmentsByStatus } from '@pages/private/shared/BbankInvest/Investments/ListByStatus';
import { ListInvestmentsPercentages } from '@pages/private/shared/BbankInvest/InvestmentsPercentage/List';
import { GeneratePixQRCode } from '@pages/private/shared/Bwallet/Pix/GeneratePixQRCode';
import { QRCodes } from '@pages/private/shared/Bwallet/Pix/QRCodes';
import { GenerateStaticPixQRCode } from '@pages/private/shared/Bwallet/Pix/GenerateStaticPixQRCode';
import { StaticQRCodes } from '@pages/private/shared/Bwallet/Pix/StaticQRCodes';
import { QrCodesAsImage } from '@pages/private/shared/Bwallet/Pix/QrCodesAsImage';
import { ListTransfers } from '@pages/private/admin/Bwallet/ListTransfers';
import { ListAssignorsMe } from '@pages/private/shared/Bwallet/Billets/Assignors/Me';
import { ListPayersMe } from '@pages/private/shared/Bwallet/Billets/Payers';
import { CreateBillPayments } from '@pages/private/shared/Bwallet/BillPayments/Create';
import { ListPayments } from '@pages/private/shared/Bwallet/BillPayments/List';
import { BillPaymentsDetails } from '@pages/private/shared/Bwallet/BillPayments/Details';
import { SharedFinancialDetails } from '@pages/private/shared/Financial/Details';
import { SharedListFinancial } from '@pages/private/shared/Financial/List';
import PayPIXCopyAndPaste from '@pages/private/shared/Bwallet/Pix/PayPIXCopyAndPaste';

import { Route } from './Route';

const sharedRoutes = [
  <Route
    key={v4()}
    isPrivate
    path="/dashboard"
    freePass
    component={Dashboard}
  />,
  <Route
    key={v4()}
    path="/faq-categories"
    isPrivate
    exact
    freePass
    component={FaqCategories}
  />,
  <Route
    key={v4()}
    path="/faq-categories/:faqCategoryId/faqs"
    isPrivate
    freePass
    component={Faqs}
  />,
  <Route
    key={v4()}
    path="/shared/sells/pos-extract"
    component={POSTransactions}
    isPrivate
    freePass
  />,
  <Route
    key={v4()}
    path="/shared/news/:newsId"
    component={ViewNews}
    isPrivate
    freePass
  />,
  <Route
    key={v4()}
    path="/shared/tickets/new"
    component={CreateTickets}
    isPrivate
    freePass
  />,
  <Route
    key={v4()}
    path="/shared/tickets/me"
    component={ListTickets}
    isPrivate
    freePass
  />,
  <Route
    key={v4()}
    path="/shared/tickets/:ticketId/view"
    component={UpdateTickets}
    isPrivate
    freePass
  />,
  <Route
    key={v4()}
    path="/shared/clients/new"
    component={CreateClients}
    isPrivate
    freePass
  />,
  <Route
    key={v4()}
    path="/shared/clients/list"
    isPrivate
    freePass
    component={ListClientsFromAnybody}
  />,
  <Route
    key={v4()}
    path="/shared/clients/:userId/details"
    isPrivate
    freePass
    component={SharedClientDetails}
  />,
  <Route
    key={v4()}
    path="/shared/bbank-invest/investments/new"
    isPrivate
    freePass
    component={NewInvestment}
  />,
  <Route
    key={v4()}
    path="/shared/bbank-invest/investments/status"
    isPrivate
    freePass
    component={ListInvestmentsByStatus}
  />,
  <Route
    key={v4()}
    path="/shared/bbank-invest/investments/percentages"
    isPrivate
    freePass
    component={ListInvestmentsPercentages}
  />,
  <Route
    key={v4()}
    path="/shared/bbank-invest/investments/list"
    isPrivate
    freePass
    component={ListInvestments}
  />,
  <Route key={v4()} path="/me" component={MyInformation} isPrivate freePass />,

  <Route
    key={v4()}
    path="/Bwallet/Transfer/New"
    isPrivate
    freePass
    component={NewTransfer}
  />,
  <Route
    key={v4()}
    path="/Bwallet/Transfer-To-Account/New"
    isPrivate
    freePass
    component={TransferToAccount}
  />,
  <Route
    key={v4()}
    path="/Bwallet/Operation/New"
    isPrivate
    freePass
    component={OperationP2P}
  />,

  <Route
    key={v4()}
    path="/bwallet/billets/all"
    isPrivate
    freePass
    component={ListBillets}
  />,
  <Route
    key={v4()}
    path="/bwallet/billets/new"
    isPrivate
    freePass
    component={NewBillet}
  />,
  <Route
    key={v4()}
    path="/bwallet/billets/:billetId/details"
    isPrivate
    freePass
    component={BilletDetails}
  />,
  <Route
    key={v4()}
    path="/Bwallet/CardPayment/New"
    isPrivate
    freePass
    component={NewCardPayment}
  />,
  <Route
    key={v4()}
    path="/Bwallet/PaymentPix/New"
    isPrivate
    freePass
    component={NewPaygamentPix}
  />,
  <Route
    key={v4()}
    path="/shared/bwallet/pix/qr-code/dynamic/new"
    isPrivate
    freePass
    component={GeneratePixQRCode}
  />,
  <Route
    key={v4()}
    path="/shared/bwallet/pix/qr-code/dynamic/list"
    isPrivate
    freePass
    component={QRCodes}
  />,
  <Route
    key={v4()}
    path="/shared/bwallet/pix/qr-code/static/new"
    isPrivate
    freePass
    component={GenerateStaticPixQRCode}
  />,
  <Route
    key={v4()}
    path="/shared/bwallet/pix/qr-code/static/list"
    isPrivate
    freePass
    component={StaticQRCodes}
  />,
  <Route
    key={v4()}
    path="/shared/bwallet/qr-code/:payload"
    isPrivate
    freePass
    component={QrCodesAsImage}
  />,
  <Route
    key={v4()}
    freePass
    isPrivate
    path="/shared/bwallet/transfer/list"
    component={ListTransfers}
  />,
  <Route
    key={v4()}
    freePass
    isPrivate
    path="/shared/bwallet/billets/assignors/me"
    component={ListAssignorsMe}
  />,
  <Route
    key={v4()}
    freePass
    isPrivate
    path="/shared/bwallet/billets/payers/me"
    component={ListPayersMe}
  />,
  <Route
    key={v4()}
    freePass
    isPrivate
    path="/shared/bwallet/bill-payments/new-payment"
    component={CreateBillPayments}
  />,
  <Route
    key={v4()}
    freePass
    isPrivate
    path="/shared/bwallet/billets/bill-payments/my-payments"
    component={ListPayments}
  />,
  <Route
    key={v4()}
    freePass
    isPrivate
    path="/shared/bwallet/billets/bill-payments/:paymentId/details"
    component={BillPaymentsDetails}
  />,
  <Route
    key={v4()}
    freePass
    isPrivate
    path="/shared/financial/list"
    component={SharedListFinancial}
  />,
  <Route
    key={v4()}
    freePass
    isPrivate
    path="/shared/financial/details/:financingReportId"
    component={SharedFinancialDetails}
  />,
  <Route
    key={v4()}
    freePass
    isPrivate
    path="/shared/bwallet/pix/new-payment"
    component={NewTransfer}
  />,
  <Route
    key={v4()}
    freePass
    isPrivate
    path="/shared/bwallet/pix/copy-and-paste/new"
    component={PayPIXCopyAndPaste}
  />,
];

export { sharedRoutes };
