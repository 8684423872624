import * as Yup from 'yup';

import { IFormData } from './interface';

export class CustomError {
  public readonly message: string;

  constructor(message: string) {
    this.message = message;
  }
}

export const validatePaymentDetails = async (
  data: IFormData,
  isAllowChange: boolean,
): Promise<void> => {
  const validationShape = Yup.object().shape({
    amount: isAllowChange
      ? Yup.number()
          .required('O valor é obrigatório')
          .min(1, 'O valor deve ser maior que zero')
          .typeError('O valor deve ser um número válido')
      : Yup.number().nullable(),

    message: Yup.string()
      .required('A mensagem de identificação é obrigatória')
      .min(10, 'A mensagem deve ter pelo menos 10 caracteres')
      .max(255, 'A mensagem não pode ter mais de 255 caracteres'),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};

export const validateQRCode = async (data: { emv: string }) => {
  const validationShape = Yup.object().shape({
    emv: Yup.string().required('O código PIX é obrigatório'),
  });

  await validationShape.validate(data, { abortEarly: false });
};
