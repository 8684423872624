import { FormHandles } from '@unform/core';
import { FC, useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { FiDollarSign } from 'react-icons/fi';
import { ValidationError } from 'yup';

import { Button } from '@components/elements/Button';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { URLPath } from '@components/layouts/UrlPath';
import { Input } from '@components/elements/Form/Input';
import { Money } from '@components/layouts/Money';
import { AuthModal } from '@components/bank/layouts/AuthModal';

import { useToast } from '@hooks/toast';

import { bankApi } from '@services/bankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';

import { Card, CardContent, CardHeader } from './styles';
import {
  CustomError,
  validatePaymentDetails,
  validateQRCode,
} from './validations';

const PayPIXCopyAndPaste: FC = () => {
  const [currentStep, setStep] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const [recipientName, setRecipientName] = useState('');
  const [recipientCPF, setRecipientCPF] = useState('');
  const [amountToPay, setAmountToPay] = useState(0);
  const [paymentMessage, setPaymentMessage] = useState('');
  const [allowAmountChange, setAllowAmountChange] = useState(true);
  const [qrCodeData, setQRCodeData] = useState('');
  const [refresh, setRefresh] = useState(true);

  const { addToast } = useToast();

  const handleQRCodeSubmission = useCallback(
    async (data: { emv: string }) => {
      try {
        setLoadingRequest(true);
        formRef.current?.setErrors({});

        await validateQRCode(data);

        setQRCodeData(data.emv);

        const response = await bankApi.get('/pix/br-codes/preview', {
          params: { emv: data.emv },
        });

        const { name, taxId, amount, allowChange } = response.data.payment;

        setRecipientName(name);
        setRecipientCPF(taxId);
        setAmountToPay(amount / 100);
        setAllowAmountChange(allowChange);

        setStep(2);
        setLoadingRequest(false);

        addToast({
          title: 'Informações carregadas com sucesso!',
          type: 'success',
        });
      } catch (error: any) {
        setLoadingRequest(false);

        if (error instanceof ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else if (error.response) {
          const { status, data: responseData } = error.response;

          if (status === 401) {
            setRefresh(oldState => !oldState);
            return;
          }

          if (responseData && responseData.message) {
            const { message } = responseData;

            addToast({
              title: message,
              type: 'info',
            });
          } else {
            const errorMessage = responseData?.message || 'Erro desconhecido';

            addToast({
              title: `Erro: ${errorMessage}`,
              type: 'error',
            });
          }
        } else {
          addToast({
            title: 'Erro de conexão. Tente novamente.',
            type: 'error',
          });
        }
      }
    },
    [addToast],
  );

  const handlePaymentDetailsSubmission = useCallback(
    async (data: { amount: number }) => {
      try {
        await validatePaymentDetails(data, allowAmountChange);

        setLoadingRequest(true);

        const paymentData = {
          emv: qrCodeData,
          message: paymentMessage,
          amount: Math.round((data.amount || amountToPay) * 100),
        };

        const response = await bankApi.post('/pix/br-codes', paymentData);

        if (response.status === 200) {
          setStep(1);

          addToast({
            title: 'Pagamento realizado com sucesso!',
            type: 'success',
          });
        }
        setLoadingRequest(false);
      } catch (error: any) {
        setLoadingRequest(false);

        if (error instanceof ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        } else if (error.response) {
          const { data: responseData } = error.response;
          if (responseData && responseData.message) {
            const { message } = responseData;

            addToast({
              title: message,
              type: 'info',
            });
          } else {
            const errorMessage = responseData?.message || 'Erro desconhecido';

            addToast({
              title: `Erro: ${errorMessage}`,
              type: 'error',
            });
          }
        }
      }
    },
    [qrCodeData, amountToPay, paymentMessage, allowAmountChange, addToast],
  );

  const stepHandlers = useMemo(
    () => [handleQRCodeSubmission, handlePaymentDetailsSubmission],
    [handleQRCodeSubmission, handlePaymentDetailsSubmission],
  );

  useEffect(() => {
    if (currentStep === 1) {
      setRecipientName('');
      setRecipientCPF('');
      setAmountToPay(0);
      setPaymentMessage('');
      setAllowAmountChange(true);
      setQRCodeData('');
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 2 && amountToPay !== 0) {
      formRef.current?.setFieldValue('amount', amountToPay);
    }
  }, [currentStep, amountToPay]);

  return (
    <>
      <AuthModal refresh={refresh} />

      <Row>
        <Money />
      </Row>

      <Row>
        <URLPath paths={['PIX Copia e Cola', 'Pagar PIX Copia e Cola']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Novo pagamento</h1>
          </CardHeader>

          <CardContent>
            <Form
              onSubmit={stepHandlers[currentStep - 1]}
              ref={formRef}
              progressBar={{ active: true, steps: 2, currentStep }}
            >
              {currentStep === 1 && (
                <>
                  <FormRow separator>
                    <h1>Primeiro de tudo, insira o código PIX QR Code!</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Código PIX QR Code</label>
                      <Input name="emv" icon={FiDollarSign} upperCase={false} />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper style={{ marginTop: 20 }}>
                    <Button
                      type="submit"
                      styleType="success"
                      loading={loadingRequest}
                    >
                      Avançar
                    </Button>
                  </FormRow>
                </>
              )}

              {currentStep === 2 && (
                <>
                  <FormRow separator>
                    <h1>Detalhes de pagamento...</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome do Recebedor</label>
                      <Input name="name" value={recipientName} readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CPF do Recebedor</label>
                      <Input name="cpf" value={recipientCPF} readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor</label>
                      <InputCurrency
                        name="amount"
                        icon={FiDollarSign}
                        value={amountToPay}
                        defaultValue={amountToPay}
                        readOnly={!allowAmountChange}
                        onChange={e => {
                          if (allowAmountChange) {
                            setAmountToPay(parseFloat(e.target.value));
                          }
                        }}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>
                      Agora, para completar o pagamento, insira uma mensagem de
                      identificação para a transação.
                    </h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Mensagem</label>
                      <Input
                        name="message"
                        value={paymentMessage}
                        onChange={e => setPaymentMessage(e.target.value)}
                        placeholder="Digite uma mensagem para o pagamento"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper style={{ marginTop: 20 }}>
                    <Button
                      type="button"
                      styleType="warning"
                      onClick={() => setStep(oldState => oldState - 1)}
                    >
                      Voltar
                    </Button>
                    <Button
                      type="submit"
                      styleType="success"
                      loading={loadingRequest}
                    >
                      Confirmar
                    </Button>
                  </FormRow>
                </>
              )}
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export default PayPIXCopyAndPaste;
